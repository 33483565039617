<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ advisor.display }}</template>
    <template v-slot:header-actions>
      <v-btn icon small v-if="onEdit" @click="onEdit">
        <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
      </v-btn>
    </template>

    <template v-slot:content>
      <v-list dense subheader>
        <v-list-item>
          <v-list-item-subtitle>{{ $t('labels.displayName') }}</v-list-item-subtitle>
          <v-list-item-title class="text-right">{{ advisor.display }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'

export default {
  name: 'ShowAdvisor',
  components: { VWidget },
  props: {
    advisor: { type: Object, required: true },
    onEdit: Function
  }
}
</script>
