<template>
  <VModalWidget :submit="submit" :dismiss="dismiss" :submitting="submitting" :submit-title="$t('labels.save')">
    <template v-slot:title>{{ $t('labels.advisor') }}</template>
    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <AdvisorField :label="$t('labels.advisor')" v-model="form.advisor_id" :errors="advisorErrors" />
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { VModalWidget, BaseForm, FormErrors } from '@argon/app/components'
import { AdvisorField } from '@/advisors/components'
import { NAMESPACE } from '../store'
import DealInfoModalMixin from '../mixins/DealInfoModalMixin'

export default {
  name: 'AdvisorModal',
  extends: BaseForm,
  mixins: [DealInfoModalMixin],
  props: {
    advisorId: [String, Number]
  },
  components: {
    VModalWidget,
    FormErrors,
    AdvisorField
  },
  validations: {
    form: {
      advisor_id: { required }
    }
  },
  data: () => ({
    form: {
      advisor_id: null
    }
  }),
  computed: {
    advisor: {
      get() {
        return this.form.advisor_id
      },
      set(v) {
        this.form.advisor_id = v
      }
    },
    advisorErrors() {
      const errors = []
      if (!this.$v.form.advisor_id.$dirty) return errors
      !this.$v.form.advisor_id.required && errors.push(this.$t('required'))
      this.getServerErrors('advisor_id', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['setDealAdvisor']),
    submit() {
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.submitting = true
      let payload = {
        dealId: this.boardItem.entity.id,
        data: this.form
      }
      this.setDealAdvisor(payload)
        .then((resp) => {
          return this.getBoardItem({ url: this.entityURL })
        })
        .then(() => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.submitting = false
        })
    }
  },
  created() {
    if (this.advisorId !== 'new') {
      this.form.advisor_id = this.advisorId
    }
  }
}
</script>
