<template>
  <v-autocomplete
    v-model="localValue"
    :items="advisors"
    :loading="loading"
    :search-input.sync="filterString"
    item-text="user.display"
    item-value="user_id"
    hide-no-data
    :label="$t('advisor')"
    :error-messages="errors"
    :disabled="readonly"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { LIKE_OPERATOR } from '@argon/app/axios'
import { CustomFieldMixin } from '@argon/app/mixins'
import { NAMESPACE } from '../store'
export default {
  name: 'AdvisorField',
  mixins: [CustomFieldMixin],
  props: {
    readonly: { type: Boolean, default: false }
  },
  data: () => ({
    filterString: null,
    loading: false
  }),
  watch: {
    filterString: function (v) {
      if (v && v.length > 2) {
        this.doSearch()
      }
    },
    disabled: function (v) {
      this.filterString = null
    }
  },
  computed: {
    ...mapState(NAMESPACE, ['advisors'])
  },

  methods: {
    ...mapActions(NAMESPACE, ['listAdvisors']),
    doSearch() {
      this.loading = true
      this.listAdvisors({ filters: `__any__:${LIKE_OPERATOR}:${this.filterString}` })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    }
  },
  created() {
    if (this.value) {
      this.listAdvisors({ filters: `user_id:${LIKE_OPERATOR}:${this.value}` })
    } else {
      this.listAdvisors({ pager: { page: 1, per_page: 5 } })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "advisor": "@:labels.advisor"
  },
  "fr": {
    "advisor": "@:labels.advisor"
  }
}
</i18n>
